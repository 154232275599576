import { BrProps } from '@bloomreach/react-sdk';
import { ImageSet } from '@bloomreach/spa-sdk';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import {
  getBuildingMethodDisplayValue,
  getCurrentSiteName,
  getUrlWithBasePath,
  getUrlFromImage,
} from 'components/utils';
import { Link } from 'react-router-dom';
import { SITE_NAMES } from 'components/enums';
import undefinedImage from '../../assets/images/unavailable.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-icon.svg';
import { ReactComponent as DurangoIcon } from '../../assets/images/durango-logo-wht.svg';
import { ReactComponent as NcIcon } from '../../assets/images/nc-logo-wht.svg';
import { ReactComponent as TexasIcon } from '../../assets/images/texas-logo-wht.svg';
import { ReactComponent as WestIcon } from '../../assets/images/west-logo-wht.svg';
import { ReactComponent as DestinyIcon } from '../../assets/images/destiny-logo-wht.svg';
import './BuildingCenterTileGrid.scss';

export function BuildingCenterTileGrid(props: BrProps) {
  const { documents } = props.component.getModels();

  // documents[0] is the TileGrid document
  const content = documents && props.page.getContent(documents[0])?.getData();
  const currentSite = getCurrentSiteName();

  const buildingCenters = [];
  // for (let i = 0; i < content?.buildingCenterDocuments.length; i += 1) {
  for (let i = 1; i < documents.length; i += 1) {
    buildingCenters.push(props.page.getContent(documents[i])?.getData());
  }

  // const history = useHistory();
  const handleBuildingCenterRouting = (url: string) => {
    const baseurl = getUrlWithBasePath(url);
    const win = window.open(baseurl, '_self');
    win?.focus();
    // history.push(getUrlWithBasePath(url));
  };

  function getImageSrc(buildingCenter: any) {
    return getUrlFromImage(
      props.page.getContent<ImageSet>(buildingCenter?.featuredImage?.imagelink),
      'extraLarge'
    );
  }

  function getBcLogo(buildingCenter: any) {
    switch (buildingCenter) {
      case 'Texas':
        return <TexasIcon />;
      case 'West':
        return <WestIcon />;
      case 'North Carolina':
        return <NcIcon />;
      case 'Durango':
        return <DurangoIcon />;
      case 'Destiny':
        return <DestinyIcon />;
      default:
        break;
    }
  }

  function getBcCardTitle(buildingCenter: any) {
    if (buildingCenter?.buildingCenterAlias) {
      return buildingCenter.buildingCenterAlias;
    }
    return `${buildingCenter?.city}, ${buildingCenter?.state}`;
  }

  return (
    <div className="cvc-bc-tile-grid">
      <h2 className="cvc-heading-featured">
        <span className="cvc-heading-featured-inner">{content?.cellTitle}</span>
      </h2>
      <p>{content?.gridDescription}</p>

      <div className="cvc-bc-tile-grid-cards">
        {buildingCenters.map((buildingCenter) => {
          const bcPath = getUrlWithBasePath(buildingCenter?.buildingCenterPath as string);
          return (
            <Card key={`building-center-${buildingCenter?.id}`} className="cvc-bc-tile-grid-card">
              <div className="cvc-bc-tile-grid-card-img-container">
                {currentSite === SITE_NAMES.CAVCO_DOT_COM && (
                  <div className="cvc-bc-tile-grid-card-img__overlay">
                    {getBcLogo(buildingCenter?.name)}
                  </div>
                )}
                <Card.Img
                  variant="top"
                  style={{ cursor: 'pointer' }}
                  className="cvc-bc-tile-grid-card-img"
                  onClick={() =>
                    handleBuildingCenterRouting(buildingCenter?.buildingCenterPath as string)
                  }
                  src={getImageSrc(buildingCenter) ? getImageSrc(buildingCenter) : undefinedImage}
                  alt={`${buildingCenter?.city} Building Center`}
                />
              </div>
              <Card.Body className="cvc-bc-tile-grid-card-body">
                <Link className="cvc-bc-tile-grid-card-title-link" key={bcPath} to={bcPath}>
                  <h3 className="cvc-bc-tile-grid-card-title">{getBcCardTitle(buildingCenter)}</h3>
                </Link>
                <Card.Body className="cvc-bc-tile-grid-card-text">
                  <div className="cvc-bc-tile-grid-card-info">
                    <LocationIcon className="cvc-bc-tile-grid-card-info-icon" />
                    <div className="cvc-bc-tile-grid-card-info-details">
                      {buildingCenter?.address1}, {buildingCenter?.city}, {buildingCenter?.state}{' '}
                      {buildingCenter?.zip}
                    </div>
                  </div>
                  <a href={`tel:${buildingCenter?.phone}`} className="cvc-bc-tile-grid-card-info">
                    <PhoneIcon className="cvc-bc-tile-grid-card-info-icon" />
                    <div className="cvc-bc-tile-grid-card-info-details">
                      {buildingCenter?.phone}
                    </div>
                  </a>
                </Card.Body>
                <div className="cvc-bc-tile-grid-card-cta">
                  <Link key={bcPath} to={bcPath}>
                    <Button variant="primary">Visit Website</Button>
                  </Link>
                </div>
              </Card.Body>
              <Card.Footer className="cvc-bc-tile-grid-card-footer">
                {/* <small>Manufactured Homes - Modular Homes - {mobileTableView  && <br />} Park Models</small> */}
                {buildingCenter?.buildingmethods.map((buildingMethod: string, index: number) => {
                  return (
                    <div key={`building-center-${index}`}>
                      {(index === 1 || index === 2) && (
                        <span className="cvc-bc-tile-grid-card-footer-separator"> &bull; </span>
                      )}
                      <span className="cvc-bc-tile-grid-card-footer-building-method">
                        {getBuildingMethodDisplayValue(buildingMethod)}
                      </span>
                    </div>
                  );
                })}
              </Card.Footer>
            </Card>
          );
        })}
      </div>
    </div>
  );
}

import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { Component, MenuItem, Menu as BrMenu, Page, TYPE_LINK_EXTERNAL } from '@bloomreach/spa-sdk';
import { BrManageMenuButton, BrPageContext } from '@bloomreach/react-sdk';
import { Nav, Collapse, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HeroBannerWithParams } from 'components/HeroBanner/HeroBanner';
import { HEADER_LINKS } from 'components/Header/constants';
import { LocalStorageContext } from 'components/Header/LocalStorageContext';
import cloneDeep from 'lodash/cloneDeep';
import { ReactComponent as CaretDownIcon } from '../../assets/images/caret-down.svg';
import { GoToPageUrl } from '../Autocomplete/Autocomplete';
import { useStateWithLocalStorage } from '../hooks/useStateWithLocalStorage';
import { HEADER_CONTENT_TYPE } from '../enums';
import SiteGlobalSwitches, {
  isFrontendRunningLocally,
  isBackendRunningLocally,
  getBuildingCenterFromHeader,
  isRetailerDetailPage,
} from '../utils';

interface MenuLinkProps {
  item: MenuItem;
  menuPath?: string;
  isRetailerFooter?: boolean;
  template?: string;
}

function MenuText({ item }: MenuLinkProps) {
  return (
    <span className="cvc-header-comp-navbar-nav-link nav-link _disabled">{item.getName()}</span>
  );
}

function SearchModal(linkClass: any, item: any, windowDimensions: any, page: any, heroBanner: any) {
  const [request, setRequest] = useState(false);
  const openModal = () => setRequest(true);
  const closeModal = () => setRequest(false);
  return (
    <div className={linkClass}>
      <button
        type="button"
        className="cvc-header-comp-navbar-nav-link nav-link"
        onClick={openModal}
      >
        {item.name}
      </button>
      <Modal
        className="cvc-header-menu-search-modal"
        show={request}
        onHide={closeModal}
        backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
        keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
        centered
        size="xl"
      >
        <Modal.Header closeButton />
        <Modal.Body id="id-modal-body">
          {heroBanner && (
            <HeroBannerWithParams
              page={page}
              component={heroBanner}
              onSearch={closeModal}
              customParams={{
                displayBackground: false,
                displayBannerBar: false,
                defaultSearch: item.parameters.searchType,
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

function pathJoin(parts: any) {
  const separator = '/';
  const partsFixed = parts.map((part: any, index: any) => {
    let newPart: any;
    if (index) {
      newPart = part.replace(new RegExp(`^${separator}`), '');
    }
    if (index !== parts.length - 1) {
      newPart = part.replace(new RegExp(`${separator}$`), '');
    }
    return newPart;
  });
  return partsFixed.join(separator);
}

function isInternalParkMenuItemActive(item: any, linkPath: any, pagePath: any) {
  if (linkPath === pagePath || linkPath.endsWith(pagePath)) {
    return true;
  }

  if (typeof item.getChildren === 'function' && item.getChildren().length > 0) {
    const children = item.getChildren();
    for (let i = 0; i < children.length; i += 1) {
      /* example: 'gallery/video-gallery'
      this would set the gallery menu item to be active on the 'gallery/video-gallery' page
      or a 'gallery/video-gallery/album' page */

      if (children[i].getLink() && children[i].getLink().href.includes(pagePath)) {
        return true;
      }
      return false;
    }
  }
  return false;
}

function isInternalMenuItemActive(item: any, linkPath: any, pagePath: any) {
  const { children } = item;
  if (linkPath === pagePath || linkPath.includes(pagePath)) {
    return true;
  }
  if (children?.length > 0) {
    for (let i = 0; i < children.length; i += 1) {
      /* example: 'gallery/video-gallery'
        this would set the gallery menu item to be active on the 'gallery/video-gallery' page
        or a 'gallery/video-gallery/album' page */
      if (children[i].model.links.site && children[i].model.links.site.href.includes(pagePath)) {
        return true;
      }
    }
  }
  return false;
}

function isMenuItemActive(item: any, linkPath: any, pagePath: any) {
  const { children } = item;
  if (linkPath === pagePath || linkPath === `${pagePath}/`) {
    return true;
  }
  if (children.length > 0) {
    for (let i = 0; i < children.length; i += 1) {
      /* example: 'gallery/video-gallery'
        this would set the gallery menu item to be active on the 'gallery/video-gallery' page
        or a 'gallery/video-gallery/album' page */
      if (children[i].model.links.site && pagePath.includes(children[i].model.links.site.href)) {
        return true;
      }
    }
  }
  return false;
}

function MenuLink(
  item: any,
  menuPath: any,
  page: Page,
  customMenuUrl = '',
  isFirstCategoryMenuItems = false,
  isRetailerMenu = false,
  windowDimensions: any,
  isParkMenu = false,
  template?: string
) {
  const itemModel = item.model || item;

  if (!itemModel.links.site && customMenuUrl === '') {
    return <MenuText key={itemModel.name} item={item} />;
  }
  const isFpDetail = !!isRetailerDetailPage(page);
  let menuLink: any;
  // for site fully qualified links or external links use the url
  // otherwise base path on current location, a page based value, and link

  if (
    (customMenuUrl !== '' && customMenuUrl !== '.' && !isRetailerMenu) ||
    itemModel.links?.site?.href.startsWith('/') ||
    itemModel.links?.site?.href.startsWith('http')
  ) {
    if (itemModel.links?.site && isParkMenu) {
      // Customization for Park Menu Items
      const locationNameSplitted = window.location.pathname && window.location.pathname.split('/');
      const lastItemFromLocationName = locationNameSplitted[locationNameSplitted.length - 1];

      menuLink = window.spaBaseUrl + itemModel.links.site.href;

      let linkPath = null;
      if (isFrontendRunningLocally() && isBackendRunningLocally()) {
        linkPath = menuLink.includes(process.env.REACT_APP_CMS_BASE_URL)
          ? new URL(menuLink.replace(process.env.REACT_APP_CMS_BASE_URL, window.location.origin))
              .pathname
          : new URL(window.location.origin + menuLink).pathname;
      } else {
        linkPath = !menuLink.includes(window.location.origin)
          ? new URL(window.location.origin + menuLink).pathname
          : new URL(menuLink).pathname;
      }

      const pagePath = lastItemFromLocationName;

      if (pagePath !== '/' && pagePath !== '/site/' && pagePath !== '/site' && pagePath !== '') {
        itemModel.selected = isInternalParkMenuItemActive(item, linkPath, pagePath);
      }
    } else if (itemModel.links.site) {
      const urlNoParam = window.location.href.includes('?')
        ? window.location.href.split('?')[0].replace(/#.*\/?/, '')
        : window.location.href;

      const itemHref = itemModel.links.site.href;
      menuLink = itemHref && itemHref.startsWith('http') ? itemHref : window.spaBaseUrl + itemHref;

      let linkPath = null;
      if (isFrontendRunningLocally() && isBackendRunningLocally()) {
        linkPath = menuLink.includes(process.env.REACT_APP_CMS_BASE_URL)
          ? new URL(menuLink.replace(process.env.REACT_APP_CMS_BASE_URL, window.location.origin))
              .pathname
          : new URL(window.location.origin + menuLink).pathname;
      } else {
        linkPath = menuLink.startsWith('/')
          ? new URL(window.location.origin + menuLink).pathname
          : new URL(menuLink).pathname;
      }
      const pagePath = new URL(urlNoParam).pathname;

      if (pagePath !== '/' && pagePath !== '/site/' && pagePath !== '/site' && pagePath !== '') {
        itemModel.selected = isInternalMenuItemActive(item, linkPath, pagePath);
      }
    }
  } else if (itemModel.links.site) {
    // clean url params and hashes, acccounts for cavhomes floorplan detail adding 2 extra segments to url
    const urlNoParam = window.location.href.split('?')[0].replace(/#.*\/?/, '');
    const menuPathFallback = (path: string) => {
      if (!path) return '.';
      return path && isFpDetail ? `${menuPath}../../` : menuPath;
    };
    menuLink = pathJoin([urlNoParam, menuPathFallback(menuPath), itemModel.links.site.href]);
    const linkPath = new URL(menuLink).pathname;
    const pagePath = new URL(urlNoParam).pathname;
    itemModel.selected = isMenuItemActive(item, linkPath, pagePath);
  }

  const notMobileAndTablet = !windowDimensions.isMobile && !windowDimensions.isTablet;
  const closeMobileMenu = () => {
    if (notMobileAndTablet) {
      return;
    }
    const closeMenuButtons = document.getElementsByClassName(
      'cvc-header-comp-navbar-sidebar-closer'
    );
    const closeMenuButton: HTMLElement = closeMenuButtons && (closeMenuButtons[0] as HTMLElement);
    closeMenuButton.click();
  };

  if (
    (template === 'cavcohome-retailer' || template === 'cavco-floorplans-detail') &&
    itemModel?.links.site &&
    isRetailerMenu &&
    itemModel?.links.site.type === TYPE_LINK_EXTERNAL &&
    menuLink?.startsWith('https')
  ) {
    return (
      <Nav.Link
        href={menuLink}
        key={itemModel.name}
        className={`cvc-header-comp-navbar-nav-link nav-link ${itemModel.selected ? 'active' : ''}
        ${!isFirstCategoryMenuItems && !isRetailerMenu ? 'is-sub-menu-item' : ''}
        ${isFirstCategoryMenuItems && isRetailerMenu && notMobileAndTablet ? 'text-uppercase' : ''}
        `}
      >
        {itemModel.name}
      </Nav.Link>
    );
  }
  if (
    itemModel.links.site &&
    itemModel.links.site.type === TYPE_LINK_EXTERNAL &&
    menuLink?.startsWith('https')
  ) {
    return (
      <Nav.Link
        href={menuLink}
        key={itemModel.name}
        target="_blank"
        className={`cvc-header-comp-navbar-nav-link nav-link ${itemModel.selected ? 'active' : ''}
        ${!isFirstCategoryMenuItems && !isRetailerMenu ? 'is-sub-menu-item' : ''}
        ${isFirstCategoryMenuItems && isRetailerMenu && notMobileAndTablet ? 'text-uppercase' : ''}
        `}
        onSelect={closeMobileMenu}
      >
        {itemModel.name}
      </Nav.Link>
    );
  }

  return (
    <Nav.Link
      as={Link}
      key={itemModel.name}
      to={customMenuUrl === '' ? menuLink : customMenuUrl}
      className={`cvc-header-comp-navbar-nav-link nav-link ${itemModel.selected ? 'active' : ''}
      ${!isFirstCategoryMenuItems && !isRetailerMenu ? 'is-sub-menu-item' : ''}
      ${isFirstCategoryMenuItems && isRetailerMenu && notMobileAndTablet ? 'text-uppercase' : ''}
      `}
    >
      {itemModel.name}
    </Nav.Link>
  );
}

function getKey(name: string, index: number): string {
  return `${name}-${index}`;
}

function buildMenu(
  menuComp: Component,
  page: Page,
  shouldShowMore = false,
  openMenuItems = false,
  windowDimensions: any,
  history: any,
  currentFloorPlanSearch: any,
  currentRetailerSearch: any,
  setCurrentFloorPlanSearch: any,
  setCurrentRetailerSearch: any,
  floorplanSearchText: any,
  setFloorplanSearchText: any,
  retailerSearchText: any,
  setRetailerSearchText: any,
  isRetailerMenu: boolean,
  variantPage: any,
  localStorageSearches: any
) {
  const { menu, componentParameterMap } = menuComp.getModels<MenuModels>();
  const { menuAutocompleteConfig, menuAutocompleteLabels } = menuComp.getModels();
  const menuImpl = menu && page?.getContent<BrMenu>(menu);
  const menuPath = componentParameterMap.menuPath ? componentParameterMap.menuPath : '';
  //const showMore = menuImpl?.getItems().length > 4 && shouldShowMore;
  let showMore;
  // Adding to fix compile error where getItems may not exist
  if (menuImpl) {
    if ('getItems' in menuImpl) {
      // Now TypeScript knows that menuImpl has getItems method
      showMore = menuImpl.getItems().length > 4 && shouldShowMore;
    }
  }

  const { retailers, parkRetailers, homes, parkHomes } = menuAutocompleteConfig || {};

  const isParkMenu = componentParameterMap.menu === 'park';
  let retailerUrl = isParkMenu ? parkRetailers : retailers;
  let floorplanUrl = isParkMenu ? parkHomes : homes;
  const buildingCenterData = getBuildingCenterFromHeader(page);
  if (buildingCenterData) {
    const buildingCenterSegment = `/building-center${buildingCenterData.buildingCenterPath}`;
    retailerUrl = buildingCenterSegment + retailerUrl;
    floorplanUrl = `${buildingCenterSegment}/floorplans/search`;
  }
  const appendParamToUrl = SiteGlobalSwitches.useElasticSearchFloorplans
    ? false
    : buildingCenterData;

  const heroBanner = page.getComponent(variantPage, 'heroBanner-modal');

  const getCustomMenuUrl = (
    urlInput: string,
    dataTerms: any,
    latitude: string,
    longitude: string,
    floorPlanSearch: boolean
  ) => {
    const newUrlInput = page.getUrl(urlInput);
    const pageUrl =
      newUrlInput && newUrlInput.startsWith('http')
        ? new URL(newUrlInput)
        : new URL(window.location.origin + newUrlInput);
    // Removing domain so only path and search are left because that's all we want
    const url = pageUrl.pathname + pageUrl.search;

    const menuItemURL = GoToPageUrl({
      url,
      terms: dataTerms,
      lat: latitude,
      lng: longitude,
      appendParamOnly: floorPlanSearch,
    });

    return menuItemURL;
  };

  const getMenuUrlFromLocalStorage = (parameterSearchType: string) => {
    if (parameterSearchType === 'retailer' && currentRetailerSearch) {
      return getCustomMenuUrl(
        retailerUrl,
        currentRetailerSearch.dataTerms,
        currentRetailerSearch.latitude,
        currentRetailerSearch.longitude,
        false
      );
    }
    if (parameterSearchType === 'floorplan' && currentFloorPlanSearch) {
      return getCustomMenuUrl(
        floorplanUrl,
        currentFloorPlanSearch.dataTerms,
        currentFloorPlanSearch.latitude,
        currentFloorPlanSearch.longitude,
        appendParamToUrl
      );
    }
    return '';
  };

  const displayLink = (
    item: any,
    index: number,
    isRetailerMenuInput = false,
    isFirstCategoryMenuItems = false,
    hasNoChilds = false,
    template?: string
  ) => {
    const itemModel = item.model ? item.model : item;

    let linkClass = '';
    if (windowDimensions.isMobile || windowDimensions.isTablet) {
      linkClass = isFirstCategoryMenuItems && hasNoChilds ? 'cvc-menu-item-without-childs' : '';
    } else {
      linkClass = 'cvc-header-comp-navbar-nav-link nav-link';
    }

    if (itemModel.parameters.searchType && menuAutocompleteLabels) {
      if (
        (itemModel.parameters.searchType === 'retailer' && !currentRetailerSearch) ||
        (itemModel.parameters.searchType === 'floorplan' && !currentFloorPlanSearch)
      ) {
        return <>{SearchModal(linkClass, itemModel, windowDimensions, page, heroBanner)}</>;
      }
    }

    const isDesktop = !windowDimensions.isMobile && !windowDimensions.isTablet;
    let customMenuUrl =
      !isDesktop && itemModel.links.site && itemModel.links.site.href !== ''
        ? itemModel.links.site.href
        : '';

    if (itemModel.parameters && itemModel.parameters.searchType) {
      const searchTypeParam = itemModel.parameters.searchType;

      if (searchTypeParam === 'floorplan' || searchTypeParam === 'retailer') {
        const menuURLFromLocalStorage = getMenuUrlFromLocalStorage(searchTypeParam);

        if (menuURLFromLocalStorage) {
          customMenuUrl = menuURLFromLocalStorage;
        }
      }
    }

    return (
      <div
        className={classNames(linkClass, `${itemModel.expanded ? 'active' : ''}`)}
        key={getKey(itemModel.name, index)}
        role="menuitem"
      >
        {MenuLink(
          item,
          menuPath,
          page,
          customMenuUrl,
          isFirstCategoryMenuItems,
          isRetailerMenuInput,
          windowDimensions,
          isParkMenu,
          template
        )}
      </div>
    );
  };

  const DisplayMenuItems = (props: any) => {
    const { items, isFirstCategoryMenuItems = false, isRetailer } = props;
    return (
      <>
        {items &&
          items.map((item: any, index: number) => (
            <DisplaySingleMenu
              key={getKey(item.name ? item.name : item.model.name, index)}
              item={item}
              index={index}
              isFirstCategoryMenuItems={isFirstCategoryMenuItems}
              isRetailer={isRetailer}
            />
          ))}
      </>
    );
  };

  const DisplaySingleMenu = (props: any) => {
    const { item, index, isFirstCategoryMenuItems, isRetailer } = props;

    const itemModel = item.model ? item.model : item;

    const [open, setOpen] = useState(false);
    const handlekeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 32 || e.keyCode === 13) {
        e.preventDefault();
        setOpen(!open);
      }
    };

    const contentId = `${itemModel.name}-${index}-content`;
    let contentClass = 'cvc-header-comp-navbar-nav-link-group-content';
    let useCaret = true;
    const itemHasOnlyChild = itemModel.childMenuItems.length === 1 && itemModel;
    const onlyChildInteractiveBuilder =
      itemHasOnlyChild &&
      itemHasOnlyChild.childMenuItems[0].name === ('Interactive Builder' || 'Design Center');
    if (onlyChildInteractiveBuilder) {
      contentClass = contentClass.concat(' no-padding');

      if (
        itemModel.childMenuItems[0].parameters.searchType === 'retailer' &&
        !currentRetailerSearch
      ) {
        contentClass = contentClass.concat(' search');
      }
      if (
        itemModel.childMenuItems[0].parameters.searchType === 'floorplan' &&
        !currentFloorPlanSearch
      ) {
        contentClass = contentClass.concat(' search');
      }

      useCaret = false;
    }

    if (itemModel.childMenuItems.length) {
      return !openMenuItems ? (
        <div
          key={getKey(itemModel.name, index)}
          className="cvc-header-comp-navbar-nav-link-group"
          aria-label={`${itemModel.name} Menu`}
        >
          <div className="cvc-header-comp-navbar-nav-link-group-toggler" role="group">
            {useCaret ? (
              <>
                {displayLink(item, index, isRetailer, isFirstCategoryMenuItems, false)}
                <CaretDownIcon
                  className={classNames(
                    `cvc-header-comp-navbar-nav-link-group-toggler-icon ${open ? 'is-clicked' : ''}`
                  )}
                  onClick={() => {
                    setOpen(!open);
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handlekeyDown(e)}
                  aria-controls={contentId}
                  aria-expanded={open}
                  role="button"
                  tabIndex={0}
                />
              </>
            ) : (
              displayLink(item, index, isRetailer, isFirstCategoryMenuItems, false)
            )}
          </div>
          {!windowDimensions.isMobile && !windowDimensions.isTablet
            ? displayLink(item, index, isRetailer, isFirstCategoryMenuItems, false)
            : ''}
          <Collapse in={open}>
            <div id={contentId} className={contentClass}>
              <DisplayMenuItems
                items={itemModel.childMenuItems}
                isFirstCategoryMenuItems
                isRetailer
              />
            </div>
          </Collapse>
        </div>
      ) : (
        <>
          {displayLink(item, index, isRetailer)}
          <div
            key={`${itemModel.name}-${index.toString()}-group`}
            className="cvc-header-comp-navbar-nav-link-group"
            aria-label={`${itemModel.name} Menu`}
          >
            <Collapse in={open}>
              <div id={contentId} className={contentClass}>
                {DisplayMenuItems(itemModel.childMenuItems)}
              </div>
            </Collapse>
          </div>
        </>
      );
    }
    return (
      <>
        {displayLink(
          item,
          index,
          isRetailerMenu,
          isFirstCategoryMenuItems,
          true,
          componentParameterMap.template
        )}
      </>
    );
  };

  // Method to append details from localstorage to menuItem href if they exist
  const processMenuItems = (elements: any[]) => {
    return elements.map((item: any) => {
      const name = item.getName();
      const itemUrl = item.getLink().href;
      const newItem = cloneDeep(item);

      // find the config item based on the name property off of the data
      // if local storage contains a link at that prop, use it
      // otherwise, use what was provided
      const configItem: any = Object.values(HEADER_LINKS).find((link: any) => link.NAME === name);
      newItem.model.links.site.href = localStorageSearches?.[configItem?.KEY] || itemUrl;

      return newItem;
    });
  };

  let menuItems;

  // If the menu item is "Homes", process the child nodes
  // updating the url to be the last item searched for.
  // First let TypeScript know that menuImpl has getItems method
  if (menuImpl && 'getItems' in menuImpl) {
    menuItems = menuImpl.getItems().map((item: any) => {
      const newItem = cloneDeep(item);

      if (item.getName() === 'Homes') {
        newItem.model.childMenuItems = processMenuItems(item.getChildren());
      }

      return newItem;
    });
  }

  return (
    <div
      role="menu"
      className={`cvc-header-comp-navbar-nav navbar-nav ${
        page.isPreview() ? 'has-edit-button' : ''
      }`}
    >
      {menuImpl && <BrManageMenuButton menu={menuImpl} />}
      {/* {displayMenuItems(showMore ? menu.siteMenuItems.slice(0, 3) : menu.siteMenuItems, true)} */}
      <DisplayMenuItems
        items={showMore ? menuItems?.slice(0, 3) : menuItems}
        isFirstCategoryMenuItems
        isRetailer={isRetailerMenu}
      />
      {showMore ? (
        <div className="cvc-header-comp-navbar-nav-more">
          <span
            className="cvc-header-comp-navbar-nav-link nav-link is-show-more"
            role="button"
            tabIndex={0}
          >
            More...
          </span>
          <div className="cvc-header-comp-navbar-nav-more-content">
            {/* {displayMenuItems(menu.siteMenuItems.slice(3), true)} */}
            <DisplayMenuItems
              items={menuItems?.slice(3)}
              isFirstCategoryMenuItems
              isRetailer={isRetailerMenu}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

function MenuLinkFooter({ item, menuPath, isRetailerFooter, template }: MenuLinkProps) {
  const page = React.useContext(BrPageContext)!;
  const isFpDetail = !!isRetailerDetailPage(page);
  let menuLink = item.getLink()?.href;
  if (!menuLink) {
    return <span className="cvc-footer-navbar-nav-link nav-link disabled">{item.getName()}</span>;
  }
  const isExternal = item.getLink()?.type === TYPE_LINK_EXTERNAL;

  if (isRetailerFooter && !menuLink?.startsWith('http')) {
    // clean url params and hashes, acccounts for cavhomes floorplan detail adding 2 extra segments to url
    const urlNoParam = window.location.href.split('?')[0].replace(/#.*\/?/, '');
    const menuPathFallback = (path: string | undefined) => {
      if (!path) return '.';
      return path && isFpDetail ? `${menuPath}../../` : menuPath;
    };
    menuLink = pathJoin([urlNoParam, menuPathFallback(menuPath), item.getLink()?.href]);
  }

  if (
    (template === 'cavcohome-retailer' || template === 'cavco-floorplans-detail') &&
    item.getLink() &&
    item.getLink()?.type === TYPE_LINK_EXTERNAL &&
    menuLink?.startsWith('https')
  ) {
    return (
      <Nav.Link
        role="menuitem"
        href={menuLink}
        key={item.getName()}
        className={`cvc-footer-navbar-nav-link nav-link ${item.isSelected() ? 'active' : ''}`}
      >
        {item.getName()}
      </Nav.Link>
    );
  }

  if (isExternal && menuLink?.startsWith('https')) {
    return (
      <Nav.Link
        role="menuitem"
        href={menuLink}
        key={item.getName()}
        target="_blank"
        className={`cvc-footer-navbar-nav-link nav-link ${item.isSelected() ? 'active' : ''}`}
      >
        {item.getName()}
      </Nav.Link>
    );
  }

  if (isExternal && menuLink?.startsWith('tel:')) {
    return (
      <Nav.Link
        role="menuitem"
        href={menuLink}
        key={item.getName()}
        className={`cvc-footer-navbar-nav-link nav-link ${item.isSelected() ? 'active' : ''}`}
      >
        {item.getName()}
      </Nav.Link>
    );
  }

  return (
    <Nav.Link
      role="menuitem"
      as={Link}
      key={item.getName()}
      to={item.getLink()?.href as string}
      className={`cvc-footer-navbar-nav-link nav-link ${item.isSelected() ? 'active' : ''}`}
    >
      {item.getName()}
    </Nav.Link>
  );
}

function buildMenuFooter(
  menuComp: Component,
  page: Page,
  windowDimensions: any,
  showMenuFooterItems = true,
  isRetailerFooter = false
) {
  const { menu, componentParameterMap } = menuComp.getModels<MenuModels>();
  const menuImpl = menu && page?.getContent<BrMenu>(menu);
  const menuPath = componentParameterMap.menuPath ? componentParameterMap.menuPath : '';
  const displayLink = (item: any, index: number) => (
    <MenuLinkFooter
      key={getKey(item.name, index)}
      item={item}
      menuPath={menuPath}
      isRetailerFooter={isRetailerFooter}
      template={componentParameterMap.template}
    />
  );

  const displayFooterMenuItems = (items: any[]) => {
    const footerMenuItems = items;
    return footerMenuItems.map((item, index) => {
      const contentId = `${item.getName()}-${index}-content`;

      return item.getChildren().length > 0 && showMenuFooterItems ? (
        <div
          key={getKey(item.getName(), index)}
          className="cvc-footer-navbar-nav-link-group nav-link"
          aria-label={`${item.getName()} Menu`}
        >
          {displayLink(item, index)}
          <div id={contentId} className="cvc-footer-navbar-nav-link-group-content" role="group">
            {displayFooterMenuItems(item.getChildren())}
          </div>
        </div>
      ) : (
        displayLink(item, index)
      );
    });
  };

  return (
    <>
      {page.isPreview() ? (
        menuImpl ? (
          <div className="has-edit-button">
            <BrManageMenuButton menu={menuImpl} />
          </div>
        ) : null
      ) : (
        ''
      )}
      <div role="menu" className="cvc-footer-navbar-nav navbar-nav">
        {menuImpl && 'getItems' in menuImpl ? displayFooterMenuItems(menuImpl.getItems()) : null}
      </div>
    </>
  );
}

export function Menu(variantPage: string, page: any, windowDimensions: any, history: any) {
  const [currentFloorPlanSearch, setCurrentFloorPlanSearch] =
    useStateWithLocalStorage('currentFloorPlanSearch');
  const [currentRetailerSearch, setCurrentRetailerSearch] =
    useStateWithLocalStorage('currentRetailerSearch');
  const [floorplanSearchText, setFloorplanSearchText] = useState({
    description: '',
    lat: null,
    lng: null,
    terms: null,
  });
  const [localStorageSearches] = useContext(LocalStorageContext);

  const [retailerSearchText, setRetailerSearchText] = useState({
    description: '',
    lat: null,
    lng: null,
    terms: null,
  });
  // const page = React.useContext(BrPageContext);

  const headerComp =
    page &&
    page
      .getComponent()
      .getChildren()
      .find((comp: any) => comp.getName() === variantPage);
  const menuComp =
    headerComp && headerComp.getChildren().find((comp: any) => comp.getName() === 'menu');
  if (!page || !menuComp) {
    return null;
  }

  let openMenuItems = false;
  let isRetailerMenu = false;

  if (variantPage === HEADER_CONTENT_TYPE.RETAILER) {
    isRetailerMenu = true;

    if (windowDimensions.isMobile || windowDimensions.isTablet) {
      openMenuItems = true;
    }
  }

  return buildMenu(
    menuComp,
    page,
    true,
    openMenuItems,
    windowDimensions,
    history,
    currentFloorPlanSearch,
    currentRetailerSearch,
    setCurrentFloorPlanSearch,
    setCurrentRetailerSearch,
    floorplanSearchText,
    setFloorplanSearchText,
    retailerSearchText,
    setRetailerSearchText,
    isRetailerMenu,
    variantPage,
    localStorageSearches
  );
}

export function MenuFooter(variantPage: string, windowDimensions: any, extraMenuItems: any[] = []) {
  const page = React.useContext(BrPageContext);
  const footerComp =
    page &&
    page
      .getComponent()
      .getChildren()
      .find((comp) => comp.getName() === variantPage);
  if (!page || !footerComp) {
    return <div>No Footer Component</div>;
  }
  const menuComp = footerComp && footerComp.getChildren().find((comp) => comp.getName() === 'menu');
  const menuRef = menuComp?.getModels<MenuModels>()?.menu;
  const menuImpl = menuRef && page?.getContent<BrMenu>(menuRef);

  if (!page || !menuComp) {
    return '';
  }

  let showMenuItems = true;
  let isRetailerFooter = false;

  if (variantPage === 'retailer-footer-content') {
    showMenuItems = false;
    isRetailerFooter = true;

    if (extraMenuItems.length > 0 && (windowDimensions.isMobile || windowDimensions.isTablet)) {
      extraMenuItems.forEach((extraMenuItem: any) => {
        if (
          menuImpl &&
          'getItems' in menuImpl &&
          !menuImpl.getItems().some((item: any) => item.name === extraMenuItem.text)
        ) {
          const oldMenuItem = menuImpl.getItems()[0];

          const newMenuItem = {
            ...oldMenuItem,
            getLink: () => ({
              ...oldMenuItem.getLink(),
              site: {
                href: extraMenuItem.link,
                type: 'external',
              },
            }),
            getChildren: () => [],
            getDepth: () => 0,
            getName: () => extraMenuItem.text,
            getParameters: () => ({}),
            isExpanded: () => false,
            isRepositoryBased: () => false,
            isSelected: () => false,
          };

          menuImpl.getItems().push(newMenuItem);
        }
      });
    }
  }

  return buildMenuFooter(menuComp, page, windowDimensions, showMenuItems, isRetailerFooter);
}

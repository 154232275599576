import { BrPageContext } from '@bloomreach/react-sdk';
import React from 'react';
import { Link } from 'react-router-dom';

const isExternalUrl = (url: string) => {
  const externalUrlRegex = new RegExp(/^https?:\/\//);
  const result = externalUrlRegex.test(url);
  return result;
};

// const removeSiteOnLocal = (url = '') => {
//   if (
//     window.location.origin.includes('localhost:3000') &&
//     url &&
//     url.length &&
//     url.startsWith('/site')
//   ) {
//     return url.slice('/site'.length);
//   }
//   return url;
// };

const ExternalLink = ({ children, ...props }: any) => <a {...props}>{children}</a>;

const InternalLink = ({ children, href = '', ...props }: any) => {
  // const url = removeSiteOnLocal(href);
  const url = href;

  return (
    <Link {...props} to={url}>
      {children}
    </Link>
  );
};

const forceToString = (href = '') => {
  if (typeof href === typeof true) {
    return '';
  }

  return href.replace(/\s/g, '+');
};

export const containsAssetPath = (url: string) => {
  return url.indexOf('/assets/') > -1;
};

export const ResolverLink = ({ children, ...props }: any) => {
  const { href = '', target = '' } = props;
  const page = React.useContext(BrPageContext);

  let url = forceToString(href).replace(/\.\//, `${window.location.pathname.replace(/\/$/, '')}/`);

  if (url !== '' && url.startsWith('http')) {
    const urlLocation = new URL(url);
    const urlCmsBase = new URL(window.cmsBaseUrl);
    if (
      (urlCmsBase.origin === urlLocation.origin || window.location.origin === urlLocation.origin) &&
      !containsAssetPath(url)
    ) {
      url = urlLocation.pathname;
    }
  }

  const isUrlExternal = isExternalUrl(url);

  let pageUrl: URL;
  try {
    const sbu = window.spaBaseUrl;
    const newUrl =
      sbu && !url.startsWith(sbu) && !url.startsWith('http')
        ? sbu + page!.getUrl(url)
        : page!.getUrl(url);

    pageUrl =
      newUrl && newUrl.startsWith('http')
        ? new URL(newUrl)
        : new URL(window.location.origin + newUrl);

    // Removing domain so only path and search are left because that's all we want
    const parsedUrl = pageUrl.pathname + pageUrl.search;

    const newURL = isUrlExternal ? url : parsedUrl || url;

    const Variant = isUrlExternal ? ExternalLink : InternalLink;

    return (
      <Variant {...props} href={newURL} target={target}>
        {children}
      </Variant>
    );
  } catch (err) {
    if (typeof err === 'string') {
      // eslint-disable-next-line no-console
      console.log('ResolverLink Error string: ', err);
    } else if (err instanceof Error) {
      // eslint-disable-next-line no-console
      console.log('ResolverLink Error message: ', err!.message);
    }

    return null;
  }
};

export const formatForHistory = (url: string) => {
  // return removeSiteOnLocal(url);
  return url;
};
